<template>
    <app-main-wrapper>
        <app-card>
            <v-img max-height="300" :src="require('@/assets/skyline.jpg')"></v-img>
            <v-card-title>Welcome to CityWatch!</v-card-title>
            <v-card-text>
                CityWatch is a daily accounting of all energy and material supplying and leaving the community. Building on GridWatch which presents real time demand and generation of electricity in Ontario, CityWatch provides a real time (daily) accounting of electricity, petroleum, natural gas, building materials, and the day-to-day things we use, such as water, food, and road salt. The initiative started with Oshawa and hopes to expand across many communities.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text to="/about">Learn More</v-btn>
            </v-card-actions>
        </app-card>
        <app-card>
            <v-card-title>Getting Started</v-card-title>
            <v-card-text>
                To get started, click below and search for your city!
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" block to="/search">Lookup City</v-btn>
            </v-card-actions>
        </app-card>
    </app-main-wrapper>
</template>

<script>

import AppMainWrapper from "@/components/AppMainWrapper";
import AppCard from "@/components/AppCard";

export default {
    name: 'Home',
    components: {AppCard, AppMainWrapper},
}
</script>
